import React from 'react';

import { Flex } from 'rebass';

import { ThemeProvider } from '@clickclima/ui';

import GlobalStyles from '../GlobalStyles';

import Body from '../components/Body';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Intro from '../components/Intro';

const IndexPage = () => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Flex flexDirection="column">
        <Header />
        <Intro />
        <Body />
        <Contact />
        <Footer />
      </Flex>
    </ThemeProvider>
  );
};

export default IndexPage;
