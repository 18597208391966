import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
const clickclimaGreen = '#3bbfba';
const clickclimaGreyish = '#95b1af';
const clickclimaDarkGreyish = '#33433d';
const clickclimaExtraGreen = '#c8de7e';
const theme = {
    breakpoints: ['40em', '52em', '64em', '80em'],
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
    space: [0, 4, 8, 16, 32, 64],
    colors: {
        clickclimaGreen,
        clickclimaGreyish,
        clickclimaDarkGreyish,
        clickclimaExtraGreen,
        // Baseline Colors
        primary: clickclimaGreen,
        primaryVariant: clickclimaExtraGreen,
        secondary: 'white',
        secondaryVariant: clickclimaGreyish,
        background: '#f7f7f7',
        surface: clickclimaGreyish,
        error: '#e74e59',
        onPrimary: 'white',
        onSecondary: clickclimaGreen,
        onBackground: clickclimaDarkGreyish,
        onSurface: 'white',
        onError: 'white',
        // Action Colors
        neutral: clickclimaGreyish,
        success: clickclimaGreen,
        warning: '#f68c52',
        caution: '#e74e59',
        likely: clickclimaExtraGreen,
        // Typography Colors
        typoBackground: clickclimaDarkGreyish,
        typoSurface: 'white',
    },
    buttons: {
        nav: {
            display: 'inline-block',
            p: 2,
            color: 'onPrimary',
            fontWeight: 'bold',
            bg: 'primary',
            borderRadius: 0,
            ':hover': {
                bg: 'clickclimaDarkGreyish',
            },
        },
        rounded: {
            display: 'inline-block',
            px: 3,
            color: 'onSecondary',
            fontWeight: 'bold',
            bg: 'secondary',
            borderRadius: 20,
            ':hover': {
                bg: 'clickclimaDarkGreyish',
                color: 'typoSurface',
            },
        },
    },
};
const ThemeProvider = ({ children }) => (React.createElement(EmotionThemeProvider, { theme: theme }, children));
export default ThemeProvider;
