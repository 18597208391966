import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Flex, Text } from 'rebass';

import { CreatedByContainer } from '@clickclima/ui';

const Footer: React.FC = () => {
  const images = useStaticQuery(graphql`
    query FooterImagesQuery {
      triangulosTecnologiaLogo: file(
        relativePath: { eq: "triangulosTecnologiaLogo.png" }
      ) {
        childImageSharp {
          fixed(width: 366, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      prefeituraDeSaoCarlosLogo: file(
        relativePath: { eq: "prefeituraDeSaoCarlosLogo.png" }
      ) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      santanderUniversidadesLogo: file(
        relativePath: { eq: "santanderUniversidadesLogo.png" }
      ) {
        childImageSharp {
          fixed(width: 465, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      ufscarLogo: file(relativePath: { eq: "ufscarLogo.png" }) {
        childImageSharp {
          fixed(width: 191, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      uspLogo: file(relativePath: { eq: "uspLogo.png" }) {
        childImageSharp {
          fixed(width: 124, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icmcLogo: file(relativePath: { eq: "icmcLogo.png" }) {
        childImageSharp {
          fixed(width: 171, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cemeaiLogo: file(relativePath: { eq: "cemeaiLogo.png" }) {
        childImageSharp {
          fixed(width: 86, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <Flex
      bg="clickclimaDarkGreyish"
      pt={['20px', '20px']}
      pb={[0, '5px']}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      overflowX="auto"
    >
      <Text
        color="typoSurface"
        pt="15px"
        pb="30px"
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: ['16px', '22px'],
          lineHeight: '26px',
          letterSpacing: '0.05em',
        }}
      >
        Apoio e Patrocínio
      </Text>
      <Flex
        width="100%"
        maxWidth={[1250]}
        justifyContent="space-around"
        alignItems="center"
        flexWrap="wrap"
      >
        {Object.entries(images).map(([key, logo]: any) => (
          <Box key={key} m={['25px']}>
            <Img key={key} fixed={logo.childImageSharp.fixed} />
          </Box>
        ))}
      </Flex>
      <CreatedByContainer message="Desenvolvido por Triângulos Tecnologia Ltda. 2020." />
    </Flex>
  );
};

export default Footer;
