import React from 'react';

import { Flex } from 'rebass';

import { Button, Logo } from '@clickclima/ui';

const Header: React.FC = () => {
  return (
    <Flex
      as="header"
      height="90px"
      width="100%"
      bg="secondary"
      alignItems="center"
      justifyContent="space-between"
      px={['10px', '25px', '70px']}
      mb="8px"
    >
      <Logo type="horizontal" height="40px" />
      <Button
        as="a"
        href="https://plataforma.clickclima.com.br"
        height="auto"
        variant="rounded"
        bg="primary"
        color="typoSurface"
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: ['14px', '20px'],
        }}
      >
        Plataforma
      </Button>
    </Flex>
  );
};

export default Header;
