import React from 'react';

import { Global, css } from '@emotion/core';

const GlobalStyles = () => (
  <Global
    styles={props => css`
      @import url('https://fonts.googleapis.com/css?family=Open+Sans');
      @import url('https://fonts.googleapis.com/css?family=Roboto');

      body {
        margin: 0;
        background-color: ${props.colors.primary};
      }
    `}
  />
);

export default GlobalStyles;
