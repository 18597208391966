import React from 'react';

import { Flex, Text } from 'rebass';

import { Doodle } from '@clickclima/ui';

const contents = [
  {
    image: <Doodle height="100%" type="participate" width="100%" />,
    title: 'Faça Parte e Compartilhe seu Clima',
    subtitle: 'Estação e Plataforma Colaborativa',
    content:
      'Iniciado no município de São Carlos - SP, Clickclima é um projeto multiobjetivos. Além de ser uma plataforma digital de dados climáticos, visa dar suporte ao desenvolvimento de uma sociedade consciente e participativa. Com estações modulares de baixo custo e de fácil instalação, possibilita que qualquer pessoa possa instalar, monitorar e compartilhar em tempo real os dados coletados. Nossa participação em espaços escolares proporciona inclusão digital integrada com conscientização ambiental. A estruturação e auditoria de dados sensoriados oferta histórico de dados de qualidade para suporte a estudos relevantes e uso em salas de aula.',
  },
  {
    image: <Doodle type="learn" height="100%" width="100%" />,
    title: 'Aprenda',
    subtitle: 'INCLUSÃO DIGITAL PARA CONSCIENTIZAÇÃO AMBIENTAL',
    content:
      'A plataforma colaborativa delineia e sustenta práticas que auxiliam na formação de crianças e adolescentes por meio de vivências focadas na valorização e preservação de recursos naturais através de aulas integradas com professores e profissionais altamente qualificados. Com a intenção de despertar o interesse dos jovens, o projeto contribuirá para educá-los mais conscientes e participativos. Clickclima é um projeto parceiro das escolas que se utiliza desse ambiente para que os alunos tenham múltiplas experiências e ressignifiquem o espaço da escola por participarem ativamente do processo incorporando o aprendizado das tecnologias digitais na comunidade.',
  },
  {
    image: <Doodle type="build" height="100%" width="100%" />,
    title: 'Construa',
    subtitle: 'PARTICIPAÇÃO ativa e INCLUSÃO',
    content:
      'Por tratar-se de uma plataforma colaborativa, todos podem fazer parte de seu uso e aplicação. A plataforma prevê que a comunidade participe e integre sua composição desenvolvendo funcionalidades e/ou adquirindo uma de nossas estações. A participação da sociedade no projeto constitui dados mais sólidos e significativos que fazem das medidas climáticas mais precisas e de maior qualidade.',
  },
  {
    image: <Doodle type="monitoring" height="100%" width="100%" />,
    title: 'Monitore',
    subtitle: 'ACOMPANHE EM TEMPO REAL',
    content:
      'Por meio de sensoriamento em estações, a plataforma online é uma ferramenta de gerenciamento de dados que rastreia e exibe em tempo real indicadores de clima personalizados, apresentando diversas variáveis como gases que causam poluição, níveis de raios ultravioletas, temperatura, umidade, níveis de ruídos entre outras.',
  },
  {
    image: <Doodle type="research" height="100%" width="100%" />,
    title: 'Pesquise',
    subtitle: 'DADOS históricos ESTRUTURADOS',
    content:
      'A plataforma oferece a visualização exploratória de gráficos com curvas de dados históricos, ofertados de forma estruturada a fim de proporcionar materiais para pesquisas acadêmicas de alto impacto. Os dados fazem parte de uma plataforma colaborativa que para além de pesquisas acadêmicas servem de ferramenta de apoio para uso social, planejamento urbano e aulas em escolas de ensino básico e superior.',
  },
];

const Body: React.FC = () => {
  return (
    <Flex
      my="8px"
      py={['10px', '0px']}
      bg="background"
      as="main"
      width="100%"
      flexDirection="column"
    >
      {contents.map(({ image, title, subtitle, content }, index) => (
        <Flex
          key={title}
          width="100%"
          pt={['20px', '0px']}
          pb={['40px']}
          px={['15px', '30px', '50px', '100px', '200px']}
          flexDirection={['column', 'row']}
          justifyContent="center"
          alignItems={['center']}
          bg={index % 2 !== 0 ? 'rgba(59, 191, 186, 0.1);' : ''}
        >
          <Flex
            flex={['150px', '2', null]}
            justifyContent={['center', 'flex-end']}
            alignItems="center"
            mr={['0px', '30px', '80px']}
            maxWidth="270px"
          >
            {image}
          </Flex>
          <Flex
            flex={['auto', '3', '5']}
            flexDirection="column"
            alignItems={['center', 'flex-start']}
            maxWidth="600px"
          >
            <Text
              as="h2"
              sx={{
                my: ['29px'],
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: ['20px', '22px', '32px'],
                lineHeight: ['27px', '30px', '44px'],
                color: 'typoBackground',
                textAlign: ['center', 'left'],
              }}
            >
              {title}
            </Text>
            <Text
              as="h3"
              sx={{
                mb: '29px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: ['14px', '16px', '22px'],
                lineHeight: ['16px', '19px', '26px'],
                textTransform: 'uppercase',
                color: 'typoBackground',
                textAlign: ['center', 'left'],
              }}
            >
              {subtitle}
            </Text>
            <Text
              as="p"
              sx={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: ['14px', null, '15px'],
                lineHeight: ['20px', null],
                letterSpacing: '0.02em',
                color: 'typoBackground',
                textAlign: ['center', 'left'],
              }}
            >
              {content}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default Body;
