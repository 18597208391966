import React from 'react';

import { Flex, Text } from 'rebass';

const Contact = () => (
  <Flex
    p={['10px', '20px']}
    px={[null, null, '50px', '150px']}
    bg="surface"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <Flex alignItems={['center']} flexDirection="column">
      <Text
        as="h2"
        sx={{
          mb: ['11px', '29px'],
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: ['20px', '22px', '32px'],
          lineHeight: ['27px', '30px', '44px'],
          color: 'white',
        }}
      >
        Participe
      </Text>
      <Text
        as="h3"
        sx={{
          mb: '29px',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: ['14px', '22px'],
          lineHeight: '26px',
          letterSpacing: '0.05em',
          color: 'white',
          textAlign: 'center',
        }}
      >
        Saiba mais sobre o Projeto e como ter uma Estação na sua Localidade
      </Text>
      <Text
        as="p"
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: ['14px', '18px'],
          lineHeight: '21px',
          color: 'white',
        }}
      >
        Entre em contato conosco:
        <br />
        <br />
        <strong>contato@clickclima.com.br</strong>
      </Text>
    </Flex>
  </Flex>
);

export default Contact;
