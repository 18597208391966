import React from 'react';
const VerticalLogo = svgProps => {
    const { fill = '#3bbfba' } = svgProps;
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 240 162.7015" }, svgProps),
        React.createElement("path", { d: "M124.2307,162.7015a14.0125,14.0125,0,1,1,9.8077-24.0177,3.4874,3.4874,0,0,1,.0309,4.93,3.4251,3.4251,0,0,1-2.5348,1.03,3.33,3.33,0,0,1-2.388-1.0057,7.0438,7.0438,0,1,0,.026,10.0514,3.39,3.39,0,0,1,2.4477-1.0033,3.4956,3.4956,0,0,1,2.4744,1.0535,3.35,3.35,0,0,1,1.0043,2.4491,3.3846,3.3846,0,0,1-1.03,2.4741A13.5588,13.5588,0,0,1,124.2307,162.7015Z", fill: fill }),
        React.createElement("path", { d: "M143.0173,162.7015a3.5138,3.5138,0,0,1-3.4788-3.5026V128.318a3.5143,3.5143,0,0,1,3.4788-3.4784,3.4308,3.4308,0,0,1,2.4737,1.029,3.3487,3.3487,0,0,1,1.0051,2.4494v30.8809a3.4723,3.4723,0,0,1-3.4788,3.5026Z", fill: fill }),
        React.createElement("path", { d: "M156.6156,162.7015a3.5138,3.5138,0,0,1-3.4788-3.5026v-21.044a3.3884,3.3884,0,0,1,1.029-2.4737,3.3492,3.3492,0,0,1,2.45-1.0047,3.4324,3.4324,0,0,1,3.4788,3.4784v21.044a3.4723,3.4723,0,0,1-3.4788,3.5026Zm.1468-30.9054h-.3673a3.3867,3.3867,0,0,1-2.473-1.0286,3.337,3.337,0,0,1-1.03-2.45,3.3843,3.3843,0,0,1,1.03-2.474,3.44,3.44,0,0,1,2.473-1.0044h.3673a3.4731,3.4731,0,0,1,3.5033,3.4784,3.3482,3.3482,0,0,1-1.0422,2.4614A3.4187,3.4187,0,0,1,156.7624,131.7961Z", fill: fill }),
        React.createElement("path", { d: "M204.5458,162.7015a3.3867,3.3867,0,0,1-2.473-1.0286,3.4406,3.4406,0,0,1-1.0051-2.474v-10.522a7.8224,7.8224,0,0,0-1.6421-5.0524,4.53,4.53,0,0,0-3.6642-1.9916,4.48,4.48,0,0,0-3.6572,1.9828,7.8289,7.8289,0,0,0-1.6491,5.0612v10.522a3.4351,3.4351,0,0,1-.9924,2.4614,3.4883,3.4883,0,0,1-4.9594.0126,3.4409,3.4409,0,0,1-1.005-2.474v-10.522a7.8224,7.8224,0,0,0-1.6421-5.0524,4.4885,4.4885,0,0,0-3.6642-1.9916,4.5381,4.5381,0,0,0-3.6642,1.9673,7.917,7.917,0,0,0-1.6414,5.0767v10.522a3.4723,3.4723,0,0,1-3.4788,3.5026,3.514,3.514,0,0,1-3.4788-3.5026v-21.044a3.3847,3.3847,0,0,1,1.03-2.4741,3.4942,3.4942,0,0,1,4.7978-.0994,3.68,3.68,0,0,1,.63.7635,10.7849,10.7849,0,0,1,5.8057-1.6684,11.0308,11.0308,0,0,1,8.785,4.218,11.03,11.03,0,0,1,8.7844-4.218,11.204,11.204,0,0,1,8.712,4.1382,14.6266,14.6266,0,0,1,3.5511,9.8622v10.522a3.3874,3.3874,0,0,1-1.0289,2.4737A3.3414,3.3414,0,0,1,204.5458,162.7015Z", fill: fill }),
        React.createElement("path", { d: "M236.5219,162.7015a3.3871,3.3871,0,0,1-2.4737-1.0286,3.4825,3.4825,0,0,1-.8632-1.4535,3.4059,3.4059,0,0,1-1.274,1.162,12.18,12.18,0,0,1-5.9111,1.32,14.0182,14.0182,0,1,1,14-14.0246v10.522a3.3875,3.3875,0,0,1-1.029,2.4737A3.3408,3.3408,0,0,1,236.5219,162.7015Zm-6.0206-7.83a2.6618,2.6618,0,0,1,.786.11,3.3141,3.3141,0,0,1,1.7559,1.234v-7.5387a7.0556,7.0556,0,0,0-14.1111,0A7.0549,7.0549,0,0,0,226,155.7205a8.732,8.732,0,0,0,2.747-.53A6.072,6.072,0,0,1,230.5013,154.8717Z", fill: fill }),
        React.createElement("path", { d: "M14.0253,162.7015a13.5678,13.5678,0,0,1-9.913-4.1123,14.003,14.003,0,0,1,0-19.825,13.6126,13.6126,0,0,1,9.913-4.0877,13.5672,13.5672,0,0,1,9.8077,4.0073,3.4874,3.4874,0,0,1,.0309,4.93,3.4372,3.4372,0,0,1-2.5348,1.03,3.33,3.33,0,0,1-2.388-1.0057,6.7255,6.7255,0,0,0-4.9158-2.0053,6.6971,6.6971,0,0,0-4.986,2.0752,6.6752,6.6752,0,0,0-2.0818,4.9688,6.7426,6.7426,0,0,0,2.0783,4.9892,6.7357,6.7357,0,0,0,4.99,2.0544,6.7184,6.7184,0,0,0,4.9418-2.0309,3.39,3.39,0,0,1,2.4477-1.0033,3.4953,3.4953,0,0,1,2.4744,1.0535,3.35,3.35,0,0,1,1.0044,2.4491,3.3847,3.3847,0,0,1-1.03,2.4741A13.5588,13.5588,0,0,1,14.0253,162.7015Z", fill: fill }),
        React.createElement("path", { d: "M32.812,162.7015a3.5138,3.5138,0,0,1-3.4788-3.5026V128.318A3.5143,3.5143,0,0,1,32.812,124.84a3.431,3.431,0,0,1,2.4737,1.029,3.3486,3.3486,0,0,1,1.005,2.4494v30.8809a3.4721,3.4721,0,0,1-3.4787,3.5026Z", fill: fill }),
        React.createElement("path", { d: "M46.41,162.7015a3.5138,3.5138,0,0,1-3.4788-3.5026v-21.044a3.3884,3.3884,0,0,1,1.029-2.4737,3.3492,3.3492,0,0,1,2.45-1.0047,3.4324,3.4324,0,0,1,3.4787,3.4784v21.044a3.4721,3.4721,0,0,1-3.4787,3.5026Zm.1468-30.9054H46.19a3.3867,3.3867,0,0,1-2.473-1.0286,3.3373,3.3373,0,0,1-1.03-2.45,3.3842,3.3842,0,0,1,1.03-2.474A3.4406,3.4406,0,0,1,46.19,124.84h.3674A3.4733,3.4733,0,0,1,50.06,128.318a3.3479,3.3479,0,0,1-1.0423,2.4614A3.4184,3.4184,0,0,1,46.5571,131.7961Z", fill: fill }),
        React.createElement("path", { d: "M68.5253,162.7015a14.0125,14.0125,0,1,1,9.8077-24.0177,3.4874,3.4874,0,0,1,.0309,4.93,3.4439,3.4439,0,0,1-2.5348,1.03,3.33,3.33,0,0,1-2.388-1.0057,7.0438,7.0438,0,1,0,.026,10.0514,3.39,3.39,0,0,1,2.4477-1.0033,3.4953,3.4953,0,0,1,2.4744,1.0535,3.35,3.35,0,0,1,1.0044,2.4491,3.3847,3.3847,0,0,1-1.03,2.4741A13.5588,13.5588,0,0,1,68.5253,162.7015Z", fill: fill }),
        React.createElement("path", { d: "M92.2363,148.2205l11.9842,8.1542a3.4907,3.4907,0,0,1,1.3836,2.2626,3.3936,3.3936,0,0,1-.623,2.6265,3.44,3.44,0,0,1-4.875.7392l-8.8827-6.4171a3.6084,3.6084,0,0,1-1.1673-1.33v4.9432a3.4723,3.4723,0,0,1-3.4788,3.5026,3.5138,3.5138,0,0,1-3.4788-3.5026V128.318a3.5135,3.5135,0,0,1,3.4788-3.4784,3.3867,3.3867,0,0,1,2.473,1.0286,3.3481,3.3481,0,0,1,1.0058,2.45v13.4452m0,0,11.07-4.2926a3.49,3.49,0,0,1,2.6485.14,3.393,3.393,0,0,1,1.8129,2,3.44,3.44,0,0,1-2.1492,4.4377l-11.2023,4.1727", fill: fill }),
        React.createElement("path", { d: "M95.8852,60.65A1.3027,1.3027,0,0,1,95.5,60.591a1.2838,1.2838,0,0,1-.8141-.7619,31.0661,31.0661,0,0,1-1.7178-10.39,25.5371,25.5371,0,0,1,2.1495-10.3909A26.9949,26.9949,0,0,1,109.4689,24.904a27.4583,27.4583,0,0,1,21.0613,0,26.9951,26.9951,0,0,1,14.3527,14.1442,25.561,25.561,0,0,1,2.1495,10.39,31.1405,31.1405,0,0,1-1.6659,10.2542,1.2879,1.2879,0,0,1-1.8608.6465l-6.5681-3.9024a1.2771,1.2771,0,0,1-.5446-1.5486,15.3886,15.3886,0,0,0,.9638-5.45,14.8427,14.8427,0,0,0-1.36-6.2924,16.6784,16.6784,0,0,0-3.7241-5.1744,18.1711,18.1711,0,0,0-5.5222-3.5313,18.14,18.14,0,0,0-13.5018,0,18.1709,18.1709,0,0,0-5.5213,3.5313,16.6886,16.6886,0,0,0-3.7258,5.1744,14.837,14.837,0,0,0-1.3588,6.2928,15.4377,15.4377,0,0,0,.8676,5.1873,1.2767,1.2767,0,0,1-.492,1.487L96.6047,60.43A1.29,1.29,0,0,1,95.8852,60.65Z", fill: fill }),
        React.createElement("path", { d: "M124.0549,4.0549v8.11A4.0668,4.0668,0,0,1,120,16.2194h0a4.0668,4.0668,0,0,1-4.0549-4.0548v-8.11A4.0668,4.0668,0,0,1,120,0h0A4.0668,4.0668,0,0,1,124.0549,4.0549Z", fill: fill }),
        React.createElement("path", { d: "M155.9926,20.6431l-5.7344,5.7344a4.0667,4.0667,0,0,1-5.7344,0h0a4.0667,4.0667,0,0,1,0-5.7344l5.7344-5.7345a4.0669,4.0669,0,0,1,5.7344,0h0A4.0668,4.0668,0,0,1,155.9926,20.6431Z", fill: fill }),
        React.createElement("path", { d: "M165.9451,54.9561h-8.11a4.0668,4.0668,0,0,1-4.0548-4.0548h0a4.0668,4.0668,0,0,1,4.0548-4.0549h8.11A4.0668,4.0668,0,0,1,170,50.9013h0A4.0668,4.0668,0,0,1,165.9451,54.9561Z", fill: fill }),
        React.createElement("path", { d: "M74.0549,46.8464h8.11a4.0668,4.0668,0,0,1,4.0548,4.0549h0a4.0668,4.0668,0,0,1-4.0548,4.0548h-8.11A4.0668,4.0668,0,0,1,70,50.9013h0A4.0668,4.0668,0,0,1,74.0549,46.8464Z", fill: fill }),
        React.createElement("path", { d: "M89.7418,14.9086l5.7344,5.7345a4.0667,4.0667,0,0,1,0,5.7344h0a4.0667,4.0667,0,0,1-5.7344,0l-5.7344-5.7344a4.0668,4.0668,0,0,1,0-5.7345h0A4.0669,4.0669,0,0,1,89.7418,14.9086Z", fill: fill }),
        React.createElement("path", { d: "M119.9987,96.6683c-11.2706,0-19.62,16.4707-24.0441,13.5934a3.1629,3.1629,0,0,1-1.1848-1.5945l.1239-1.1725,22.9412-58.5255a2.3426,2.3426,0,0,1,4.328,0v0l22.9412,58.5263.1261,1.1595a3.1635,3.1635,0,0,1-1.187,1.6067C139.6254,113.1374,131.2685,96.6684,119.9987,96.6683Z", fill: fill })));
};
const HorizontalLogo = ({ onlyIcon = false, ...svgProps }) => {
    const { fill = '#3bbfba' } = svgProps;
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: `0 0 ${onlyIcon ? '80' : '320'} 67.9126` }, svgProps),
        !onlyIcon ? (React.createElement(React.Fragment, null,
            React.createElement("path", { d: "M200.9818,53.4185A14.4343,14.4343,0,0,1,186.5628,39a14.4131,14.4131,0,0,1,24.5019-10.2736,3.5856,3.5856,0,0,1,.0318,5.0686,3.522,3.522,0,0,1-2.606,1.0589,3.4235,3.4235,0,0,1-2.455-1.034,7.2668,7.2668,0,0,0-12.32,5.18,7.2543,7.2543,0,0,0,12.3466,5.1534,3.4849,3.4849,0,0,1,2.5164-1.0315,3.5935,3.5935,0,0,1,2.5439,1.0831,3.4441,3.4441,0,0,1,1.0325,2.5179,3.4794,3.4794,0,0,1-1.0585,2.5434A13.9392,13.9392,0,0,1,200.9818,53.4185Z", fill: fill }),
            React.createElement("path", { d: "M220.2956,53.4185a3.6125,3.6125,0,0,1-3.5764-3.6009V18.07a3.6128,3.6128,0,0,1,3.5764-3.576,3.5269,3.5269,0,0,1,2.5431,1.0578A3.4424,3.4424,0,0,1,223.872,18.07V49.8176a3.57,3.57,0,0,1-3.5764,3.6009Z", fill: fill }),
            React.createElement("path", { d: "M234.2755,53.4185a3.6125,3.6125,0,0,1-3.5764-3.6009V28.183A3.4835,3.4835,0,0,1,231.757,25.64a3.4431,3.4431,0,0,1,2.5185-1.0329,3.5287,3.5287,0,0,1,3.5764,3.576V49.8176a3.57,3.57,0,0,1-3.5764,3.6009Zm.1509-31.7727h-.3776a3.4814,3.4814,0,0,1-2.5424-1.0575,3.4309,3.4309,0,0,1-1.0586-2.5182,3.48,3.48,0,0,1,1.0586-2.5435,3.537,3.537,0,0,1,2.5424-1.0325h.3776a3.5707,3.5707,0,0,1,3.6017,3.576,3.4421,3.4421,0,0,1-1.0715,2.5305A3.5149,3.5149,0,0,1,234.4264,21.6458Z", fill: fill }),
            React.createElement("path", { d: "M283.5508,53.4185a3.4817,3.4817,0,0,1-2.5424-1.0574,3.5375,3.5375,0,0,1-1.0333-2.5435V39a8.042,8.042,0,0,0-1.6882-5.1942,4.6565,4.6565,0,0,0-3.767-2.0474,4.6056,4.6056,0,0,0-3.76,2.0384A8.0485,8.0485,0,0,0,269.0647,39V49.8176a3.5316,3.5316,0,0,1-1.02,2.5305,3.5862,3.5862,0,0,1-5.0985.013,3.5375,3.5375,0,0,1-1.0333-2.5435V39a8.042,8.042,0,0,0-1.6882-5.1942,4.6142,4.6142,0,0,0-3.767-2.0474,4.6654,4.6654,0,0,0-3.767,2.0225A8.1387,8.1387,0,0,0,251.0029,39V49.8176a3.57,3.57,0,0,1-3.5764,3.6009,3.6125,3.6125,0,0,1-3.5764-3.6009V28.183a3.48,3.48,0,0,1,1.0586-2.5435,3.5921,3.5921,0,0,1,4.9324-.1021,3.7782,3.7782,0,0,1,.6477.7848,11.0881,11.0881,0,0,1,5.9686-1.7152,11.3405,11.3405,0,0,1,9.0316,4.3364A11.3394,11.3394,0,0,1,274.52,24.607a11.5183,11.5183,0,0,1,8.9565,4.2544A15.0366,15.0366,0,0,1,287.1272,39V49.8176a3.4827,3.4827,0,0,1-1.0578,2.5431A3.4353,3.4353,0,0,1,283.5508,53.4185Z", fill: fill }),
            React.createElement("path", { d: "M316.4243,53.4185a3.4823,3.4823,0,0,1-2.5431-1.0574,3.58,3.58,0,0,1-.8874-1.4944,3.5035,3.5035,0,0,1-1.31,1.1947,12.5223,12.5223,0,0,1-6.077,1.3571A14.4343,14.4343,0,0,1,291.1881,39,14.406,14.406,0,0,1,320,39V49.8176a3.4827,3.4827,0,0,1-1.0578,2.5431A3.4348,3.4348,0,0,1,316.4243,53.4185Zm-6.19-8.05a2.7377,2.7377,0,0,1,.808.113,3.407,3.407,0,0,1,1.8051,1.2687V39a7.2536,7.2536,0,0,0-14.507,0,7.2528,7.2528,0,0,0,7.2661,7.2412,8.9747,8.9747,0,0,0,2.824-.5444A6.2446,6.2446,0,0,1,310.2348,45.3689Z", fill: fill }),
            React.createElement("path", { d: "M87.6836,53.4185A14.4343,14.4343,0,0,1,73.2647,39,14.4131,14.4131,0,0,1,97.7666,28.7267a3.5856,3.5856,0,0,1,.0318,5.0686,3.5347,3.5347,0,0,1-2.606,1.0589,3.4233,3.4233,0,0,1-2.455-1.034A7.2668,7.2668,0,0,0,80.4175,39a7.2543,7.2543,0,0,0,12.3466,5.1534,3.4847,3.4847,0,0,1,2.5164-1.0315,3.5935,3.5935,0,0,1,2.5439,1.0831,3.4441,3.4441,0,0,1,1.0325,2.5179,3.4794,3.4794,0,0,1-1.0585,2.5434A13.9394,13.9394,0,0,1,87.6836,53.4185Z", fill: fill }),
            React.createElement("path", { d: "M106.9975,53.4185a3.6125,3.6125,0,0,1-3.5764-3.6009V18.07a3.6128,3.6128,0,0,1,3.5764-3.576,3.5269,3.5269,0,0,1,2.5431,1.0578,3.4428,3.4428,0,0,1,1.0333,2.5182V49.8176a3.57,3.57,0,0,1-3.5764,3.6009Z", fill: fill }),
            React.createElement("path", { d: "M120.9774,53.4185a3.6125,3.6125,0,0,1-3.5764-3.6009V28.183a3.4838,3.4838,0,0,1,1.0578-2.5431,3.4435,3.4435,0,0,1,2.5186-1.0329,3.5287,3.5287,0,0,1,3.5764,3.576V49.8176a3.57,3.57,0,0,1-3.5764,3.6009Zm.1509-31.7727h-.3776a3.4814,3.4814,0,0,1-2.5424-1.0575A3.4309,3.4309,0,0,1,117.15,18.07a3.48,3.48,0,0,1,1.0586-2.5435,3.537,3.537,0,0,1,2.5424-1.0325h.3776A3.5707,3.5707,0,0,1,124.73,18.07a3.4418,3.4418,0,0,1-1.0716,2.5305A3.5145,3.5145,0,0,1,121.1283,21.6458Z", fill: fill }),
            React.createElement("path", { d: "M143.7131,53.4185A14.4343,14.4343,0,0,1,129.2942,39a14.4131,14.4131,0,0,1,24.5019-10.2736,3.5855,3.5855,0,0,1,.0317,5.0686,3.5413,3.5413,0,0,1-2.6059,1.0589,3.4233,3.4233,0,0,1-2.455-1.034A7.2668,7.2668,0,0,0,136.447,39a7.2543,7.2543,0,0,0,12.3466,5.1534,3.4847,3.4847,0,0,1,2.5164-1.0315,3.5931,3.5931,0,0,1,2.5438,1.0831,3.4438,3.4438,0,0,1,1.0326,2.5179,3.4791,3.4791,0,0,1-1.0586,2.5434A13.9391,13.9391,0,0,1,143.7131,53.4185Z", fill: fill }),
            React.createElement("path", { d: "M168.09,38.5311l12.3205,8.383a3.5889,3.5889,0,0,1,1.4225,2.3262,3.4889,3.4889,0,0,1-.6405,2.7,3.5364,3.5364,0,0,1-5.0119.76l-9.132-6.5971a3.7111,3.7111,0,0,1-1.2-1.3676v5.0819a3.57,3.57,0,0,1-3.5764,3.6009,3.6125,3.6125,0,0,1-3.5764-3.6009V18.07a3.6121,3.6121,0,0,1,3.5764-3.576,3.4819,3.4819,0,0,1,2.5424,1.0574,3.4422,3.4422,0,0,1,1.034,2.5186V31.8926m0,0,11.381-4.4131a3.5888,3.5888,0,0,1,2.7228.1435,3.4886,3.4886,0,0,1,1.8638,2.0561,3.5361,3.5361,0,0,1-2.2095,4.5622l-11.5167,4.29", fill: fill }))) : null,
        React.createElement("path", { d: "M15.8946,37.2415a.8.8,0,0,1-.2368-.036.7883.7883,0,0,1-.5-.4678,19.075,19.075,0,0,1-1.0548-6.38,15.6811,15.6811,0,0,1,1.32-6.3805,16.5764,16.5764,0,0,1,8.8126-8.6849,16.8613,16.8613,0,0,1,12.9326,0,16.5761,16.5761,0,0,1,8.8131,8.6852,15.6955,15.6955,0,0,1,1.32,6.38,19.1225,19.1225,0,0,1-1.0229,6.2965.7908.7908,0,0,1-1.1426.397l-4.0331-2.3962a.7843.7843,0,0,1-.3345-.951,9.4467,9.4467,0,0,0,.5919-3.3463,9.1134,9.1134,0,0,0-.8349-3.8638,10.2405,10.2405,0,0,0-2.2868-3.1773,11.1566,11.1566,0,0,0-3.3908-2.1684,11.1387,11.1387,0,0,0-8.2907,0,11.1573,11.1573,0,0,0-3.39,2.1683,10.2479,10.2479,0,0,0-2.2878,3.1773,9.11,9.11,0,0,0-.8344,3.8641,9.4779,9.4779,0,0,0,.5328,3.1852.784.784,0,0,1-.3021.9131l-3.9385,2.6509A.7921.7921,0,0,1,15.8946,37.2415Z", fill: fill }),
        React.createElement("path", { d: "M33.192,2.49V7.47a2.4971,2.4971,0,0,1-2.49,2.49h0a2.4971,2.4971,0,0,1-2.49-2.49V2.49A2.4971,2.4971,0,0,1,30.7021,0h0A2.4971,2.4971,0,0,1,33.192,2.49Z", fill: fill }),
        React.createElement("path", { d: "M52.8032,12.6757,49.282,16.1969a2.4972,2.4972,0,0,1-3.5212,0h0a2.4972,2.4972,0,0,1,0-3.5212L49.282,9.1545a2.4972,2.4972,0,0,1,3.5212,0h0A2.4972,2.4972,0,0,1,52.8032,12.6757Z", fill: fill }),
        React.createElement("path", { d: "M58.9144,33.7454h-4.98a2.4971,2.4971,0,0,1-2.49-2.49h0a2.4971,2.4971,0,0,1,2.49-2.49h4.98a2.4971,2.4971,0,0,1,2.49,2.49h0A2.4971,2.4971,0,0,1,58.9144,33.7454Z", fill: fill }),
        React.createElement("path", { d: "M2.49,28.7657H7.47a2.4971,2.4971,0,0,1,2.49,2.49h0a2.4971,2.4971,0,0,1-2.49,2.49H2.49A2.4971,2.4971,0,0,1,0,31.2556H0A2.4971,2.4971,0,0,1,2.49,28.7657Z", fill: fill }),
        React.createElement("path", { d: "M12.1223,9.1545l3.5212,3.5212a2.4972,2.4972,0,0,1,0,3.5212h0a2.4972,2.4972,0,0,1-3.5212,0L8.6011,12.6757a2.4972,2.4972,0,0,1,0-3.5212h0A2.4972,2.4972,0,0,1,12.1223,9.1545Z", fill: fill }),
        React.createElement("path", { d: "M30.7013,59.3585c-6.9206,0-12.0474,10.1137-14.764,8.3469a1.9422,1.9422,0,0,1-.7276-.9791l.0761-.72L29.3727,30.0692a1.4384,1.4384,0,0,1,2.6575,0v0L46.1171,66.0069l.0775.7119a1.9425,1.9425,0,0,1-.7289.9866C42.753,69.4712,37.6215,59.3585,30.7013,59.3585Z", fill: fill })));
};
const Logo = ({ type = 'horizontal', ...svgProps }) => {
    if (type === 'horizontal') {
        return React.createElement(HorizontalLogo, Object.assign({}, svgProps));
    }
    if (type === 'vertical') {
        return React.createElement(VerticalLogo, Object.assign({}, svgProps));
    }
    return null;
};
export default Logo;
