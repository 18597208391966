import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Flex, Text } from 'rebass';

const Intro: React.FC = () => {
  const data = useStaticQuery(graphql`
    query IntroImageQuery {
      placeholderImage: file(relativePath: { eq: "ambientalMonitoring.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 720) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Box
      width="100%"
      height={['260px', '628px', '720px']}
      sx={{ position: 'relative' }}
    >
      <Img
        style={{
          opacity: '0.8',
          maxHeight: '100%',
          height: '100%',
          width: '100%',
        }}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
      <Box
        width="100%"
        height={['55px', '128px']}
        bg="primary"
        opacity="0.8"
        sx={{
          position: 'absolute',
          bottom: '0',
        }}
      />
      <Flex
        width="100%"
        height={['55px', '128px']}
        justifyContent="center"
        sx={{
          position: 'absolute',
          bottom: '0',
        }}
      >
        <Text
          as="h1"
          opacity={1}
          sx={{
            color: 'onPrimary',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: ['18px', '28px', '40px'],
            lineHeight: ['21px', '33px', '47px'],
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          ClickClima - o seu consultor climático a um clique de distância
        </Text>
      </Flex>
    </Box>
  );
};

export default Intro;
